import { useRef, useState, Suspense, useEffect } from 'react'

import {
  useDynamicContext,
  DynamicAuthFlow,
  DynamicContextProvider,
} from '@dynamic-labs/sdk-react';

function Home() {

  const { user, handleLogOut, setShowAuthFlow, showAuthFlow, walletConnector } =
    useDynamicContext();


  const walletRef = useRef(walletConnector)
  const userRef = useRef(user)

  const provider = walletConnector?.getWeb3Provider();
  let providerRef = useRef(provider)

  const signer = provider?.getSigner()
  let signerRef = useRef(signer)

  useEffect(() => {
    if (user && walletConnector) {
      walletRef.current = walletConnector;
      userRef.current = user;
      const provider = walletRef.current.getWeb3Provider();
      providerRef.current = provider;

    }
  }, [user, walletConnector]);


  if (user && !showAuthFlow) {

    let provider = walletConnector?.getWeb3Provider()
    providerRef.current = provider;
    let signer = provider?.getSigner();
    signerRef.current = signer;

    walletRef.current = walletConnector;
    userRef.current = user;

    return (
      <div id='walletWrapper'>
        <div className='overlay'>
          <div class="btn">
            <button type='button' onClick={
              ()=>{
                handleLogOut()
              }}>
              Thank You!
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='overlay'>
      <div class='btn'>
        <button type='button' onClick={() => setShowAuthFlow(true)}>
          Connect Wallet to Register!
        </button>
      </div>
      {showAuthFlow && (
        <DynamicAuthFlow
          onAuthSuccess={({ authToken, user }) => {
            setShowAuthFlow(false)
          }}
        />
      )}
    </div>
  )
}




export default function App() {

  return (
    <div id='wrapper'>
      <div id='dynamic'>
        <DynamicContextProvider
          settings={{
            appLogoUrl:
              'https://upload.wikimedia.org/wikipedia/commons/3/34/Examplelogo.svg',
            appName: 'Wallet Connect',
            environmentId: 'dd06bb92-9f12-495d-aed2-e1950ea92b81',
          }}>
          <Home />
        </DynamicContextProvider>
      </div>
    </div>
  )
}